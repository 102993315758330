.App {
    background-color: var(--colorternere);
    color: white;
    border: 5px solid;
    border-color:var(--colorternerelight);
    border-width: 1px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}


