:root {
  --colorp2: #ffffff;
  --colorprimary:  white;
  --colorsecondary: #000000;
  --colorternere: #1a1919;
  --colorternerelight: #262525 ;
  --colorternerelight2: #5f5a5a ;
  --colorlinks: #4aedb4;
  --colorlinks2: #4285F4;
  --ff: 'Open Sans', 'sans-serif';
  --transition: 0.3s ease-in-out;
  --transitionfast: 0.2s ease-in-out;
  --h1: bold 54px/70px var(--ff);
  --h2: bold 36px/36px var(--ff);
  --h3: bold 24px/30px var(--ff);
  --links: bold 18px/18px var(--ff);
  --p2: bold 30px/36px var(--ff);
  --p2but: bold 14px/26px var(--ff);
  --p: light 18px/30px var(--ff);
}
body.light-mode {
  --colorp2: #020202;
  --colorprimary:  rgb(255, 255, 255);
  --colorsecondary: #efeded;
  --colorternere: #fdfdfd;
  --colorternerelight: #f4f4f4 ;
  --colorternerelight2: #c8c8c8 ; /*svg , links color before fill*/
  --colorlinks: #4aedb4;
  --colorlinks2: #4285F4;
}
h1,
h2,
h3,
p,
a,
button,
span,
.links,
.p2 {
  color: var(--colorp2);
  margin: 0px;
}
h1{font: var(--h1);}
h2{font: var(--h2);}
h3{
  font: var(--h3);}
.links{font: var(--links);}
.p2{font: var(--p2);}
p{font: var(--p);}
html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--ff);
  padding: 0;
  margin: auto;
  background-color: var(--colorsecondary);
  ;
}

svg {
  fill: var(--colorternerelight2);
  width: 50px;
  height: 25px;
}

svg:hover {
  fill: var(--colorlinks);
  cursor: pointer;
}

.section {
  max-width: 1080px;
  margin: auto;
}

p {
  line-height: normal;
}
