.project-page {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px
}

.project-page h1{
    font-size: 45px;
    line-height: 1.2;
}
.project-page p{
    /* font-weight: lighter; */
    font-size: 16px;
    line-height: 1.3;
}
.proj-keyword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 1px solid;
    border-color: var(--colorternerelight2);
    border-radius: 15px;
    height: auto;
    padding: 15px 0;
}
.proj-keyword:hover {
    transition: var(--transition);
    border-color: var(--colorlinks);
    cursor: pointer;
}
.proj-keyword:hover button {
    transition: var(--transition);
    border-color: var(--colorlinks);
}
.proj-keyword > h1 {
    font-size: 35px;
}
.keyword-buttons {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 13px;
}
.keyword-buttons button {
    text-align: center;
    width: 200px;
    height: 50px;
    margin: 0;
    padding: 0 22px 0 20px;
    border: 1px solid;
    border-radius: 25px;
    background-color: var(--colorternerelight);
    /* border-color: var(--colorlinks); */
    font: var(--p2but);
}
.lang-key > button {
    width: 150px;
}
.keyword-buttons button.active {
    background-color: var(--colorlinks2);
    border-color: var(--colorlinks2);
}
.keyword-buttons button:hover {
    transition: var(--transition);
    cursor: pointer;
    background-color: var(--colorlinks2);
    /* color: var(--colorsecondary); */
}
.all-Project > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}
.projects-grid > li {
    padding: 5% 0% 0% 5%;
    border-radius: 25px;
    height: 100%;
}
.projects-grid > li:hover {
    background-color: var(--colorternerelight);
    cursor: pointer;
    /* transition: var(--transition); */
    border: 1px solid ;
    border-color: var(--colorlinks);
}
li > .proj-grid {
    /* background-color: var(--colorternerelight); */
    border-radius: 15px;
    height: 100%;
    width: 97%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    align-items: center;
}
.project-keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.project-keywords > a {
    border-radius: 15px;
    padding: 0;
    height: 30px;
    width: 80px;
    background-color: var(--colorlinks2);
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.git-link {
    border-radius: 15px;
    width: 95%;
    padding: 5px 0;
    font-style: bold;
    font-size: 17px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
}
.git-link:hover {
    transition: var(--transition);
    background-color: var(--colorlinks);
    cursor: pointer;
    color: var(--colorsecondary);
    svg {
        fill : black;
    }
}
.projects-grid > li:hover .link-proj-desc svg {
    fill: var(--colorlinks);
}
.projects-grid > li:hover .link-proj-desc a {
   color: var(--colorlinks);
}
li > .proj-grid > .proj-grid-el {
    /* padding: 5% 0% 0% 5%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    /* margin-bottom: 20px; */
}
.proj-grid h3 {
    font-size: 20px;
    line-height: 1;
}
.proj-grid p {
    font-weight: lighter;
    font-size: 15px;
    line-height: 1.2;
}
