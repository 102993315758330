
.Home-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    padding: 40px;
}

.Home-section > .Intro-home > h1{
    font-size: 40px;
    line-height: 1;
    margin-bottom: 10px;
}
.Home-section p {
    font-weight: lighter;
}
.img-container > img {
    height: 130px;
    width: 120px;
    border-radius: 50%;
}

.social-icons {
    display: flex;
}
.social-icons > a{
    display: flex;
    justify-content: start;
    height: 30px;
    padding: 0;
    padding-top: 20px;
}
.social-icons img {
    width: 40px;
    cursor: pointer;
}


.exp-section {
    display: flex;
    justify-content: space-between;
}
.top-proj {
    width: 45%;
}
.sum-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 60px;
}


@media (max-width: 800px) {
    .Home-section {
        padding: 20px;
    }
   .exp-section {
        flex-direction: column-reverse;
        gap: 20px;
    } 
    .top-proj {
        width: 90%;
    }
    .sum-container {
        width: 100%;
    }
}
