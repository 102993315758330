.Project {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.Project  ul {
    margin: 0;
    padding: 0;
    width: 90%;
    padding-top: 40px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px
}
.Project  ul > li {
    height: 200px;
    width: 100%;
    padding: 5%;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: var(--colorternerelight);
    border-radius: 15px;
}
ul > li > .proj-li {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}
.proj-li > .cal {
    padding: 0;
    height: 20px;
    font-size: 14px;
    display: flex;
    gap: 10px;
}
.proj-li > .cal > .bar{
    border: 1px solid;
    border-width: 0.3px;
    height: 80%;
}
.Project p {
    font-weight: lighter;
    font-size: 13px;
}

.project-icon > img {
    height: 40px;
    border-radius: 50%;
}
.link-proj-desc > a {
    padding: 0px;
    font-size: 15px;
}
.link-proj-desc > svg {
    height: 15px;
    width: 25px;
}

.link-proj-desc {
    display: flex;
    align-items: center;
    padding-top: 10px;
    gap: 10px;
    height: 10px;
}

.Project li:hover {
    transition: var(--transitionfast);
    /* transition-behavior: allow-discrete; */
    cursor: pointer;
    border-radius: 15px;
    background-color: var(--colorternerelight);
    border-color: var(--colorlinks);
    svg {
        fill: var(--colorlinks);
    };
    a {
        color: var(--colorlinks);
    };
}
