.navbar {
    /* position: fixed; */
    background-color: var(--colorternere);
    color: var(--colorprimary);
    border-radius: 35px;
    height: 40px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    border-bottom: solid;
    /* border-bottom: 3px solid; */
    border-width: 3px;
    border-color: var(--colorlinks);
  }
.navbar.open {
    display: none;
}
.hamburger {
    display: none;
}
nav button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 29px;
    border: 1px solid var(--colorlinks);
    border-radius: 15px;
    background-color: var(--colorternere);
    gap: 30px;
    margin: 0;
    height: 100%;
}
nav > button:hover {
    transition: var(--transition);
    padding: 0px 15px;
    cursor: pointer;
}
nav > button > svg {
    height: 70%;
    width: 20px;
}
nav > button > svg:hover {
    cursor: pointer;
}
.modal-overlay {
    position: fixed;
    z-index: 80;
    inset: 0;
    scale: 1;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    transition: var(--transition);
}
.modal-header {
    display: flex;
    justify-content: space-between;
}
.modal-header > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    background-color: var(--colorternere);
    gap: 30px;
    margin: 0;
    height: 100%;
}
.modal-header > button:hover {
    transition: var(--transition);
    border: 1px solid var(--colorlinks);
    background-color: var(--colorternere);
    border-radius: 15px;
    padding: 0px 13px;
    cursor: pointer;
}
.modal-header > button {
    height: 70%;
    width: 40px;
}

nav ul{
    list-style-type: none;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
}
.modal-container {
    padding: 15px;
    border: 1px solid var(--colorlinks);
    background-color: var(--colorternere);
    border-radius: 15px;
    width: 85%;
    transform: translate(5%, 20%);
}
.modal-nav > ul {
    display: flex;
    flex-direction: column;
    align-items: start;
}
.modal-nav > ul > li {
    display: flex;
    align-items: start;
    justify-content: start;
}
.modal-nav > ul  > span {
    border: 1px solid;
    border-width: 0.1px;
    width: 100%;
    border-color: var(--colorlinks)
}
.nav-items2 {
    display: none;
    flex-direction: column;
    width: 90%;
}
.nav-items2.open {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.modal-nav > ul > li > a {
    text-decoration: none;
    color: var(--colorp2);
    padding: 20px 0px 10px 0px;
}

.navbar > li , a {
    display: block;
    color: var(--colorp2);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

ul > li> a:hover {
    color: var(--colorlinks);
    transition: var(--transition);
}


@media (max-width: 750px) {

    .navbar {
        border-bottom: none;
    }
    .hamburger {
        display: flex;
    }
    
    .nav-items {
        display: none;
    }
    .nav-items2.open {
        display: flex;
        flex-direction: column;
        transition: var(--transition);
        translate: 0% 70%;
        background-color: var(--colorternerelight);
        border: 1px solid;
        border-radius: 15px;
        border-color: var(--colorlinks);
        backdrop-filter: blur(10px);
        width: 100%;;
        height: 300px;
    }
    .nav-items2 > ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
    }
    .nav-items li {
        width: 100%;
    }
}
