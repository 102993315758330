.about-section {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    padding: 0 40px 0 40px;
    gap: 15px;
}
.about-p {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.about-section h1 {
    line-height: 1.2;
    width: 85%;
    font-size: 40px; 
}
.about-img > img{
    height: 300px;
    width: 270px;
    border-radius: 15px;
}

.about-socials {
    display: flex;
    flex-direction: column;
    align-items: start;
}
.about-socials > a {
    display: flex;
    align-items: center;
}
.about-socials > a:hover {
    svg {
        fill: var(--colorlinks);
    }
    p {
        color: var(--colorlinks);
    }
}
.email-sec {
    display: flex;
    flex-direction: column;
    align-items: start;
}
.emailsvg > a {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.emailsvg > a:hover {
    svg {
        fill: var(--colorlinks);
    }
    p {
        color: var(--colorlinks);
    }
}
.p-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
}

.p-div > p {
    font-weight: lighter;
    line-height: 1.7;
    color: var(--colorp2);
    font-size: 17px; 
}

.lin-sep {
    border: 1px solid;
    width: 100%;
    border-width: 0.3px;
    border-color: var(--colorternerelight);
}

@media (max-width: 750px) {
    .about-section {
        flex-direction: column-reverse;
    }
    .about-contact {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap:20px;
    }
    .about-p > h1 {
        width: 100%;
        line-height: 1.4;
        font-size: 30px; 
        display: flex;
        justify-content: center;
    }
}
