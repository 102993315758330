header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
}

.img-icon-container {
    display: flex;
    align-items: center;
    float: left;
  }

.logo {
    height: 35px;
    width: 33px;
    border-radius: 50%;
}

.mode {
    background-color: var(--colorternarelight);
    border: thin double;
    border-color: var(--colorlinks);
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
    float: right;
}
.mode:hover {
    border-color: var(--colorlinks);
    transform: 2px;
    translate: 0px 2px;
    padding: 1%;
}
.mode > button{
    background-color: none;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

button > svg {
    height: 35px;
    width: 35px;
}


@media (max-width: 750px) {
}
